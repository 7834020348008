import { baseFakeFeatures } from "@lib/featureFlipper/fakes";
import { mergeDeep } from "@lib/mergeDeep";
import sharedConstants from "@lib/shared_constants.json";

import type { DashboardConfig, PartialDashboardConfig } from "./types";

export const baseFakeConfig: DashboardConfig = {
  region: "us-east-1",
  cluster: "01",
  editorDomainUrl: "http://fake-editor-domain.com",
  dashboardUrl: "http://fake-dashboard-url.com",
  iAccess: true,
  i: false,
  roi: false,
  h: true,
  flash: {},
  user: {
    developer: {
      _id: "678876c8687f86adf678d321",
      email: "fake.user@braze.com",
      first_name: "Fake",
      last_name: "User",
      full_name: "Fake User",
      department: "Engineering",
      image_url:
        "https://i0.wp.com/i.dailymail.co.uk/1s/2021/07/29/20/46061771-0-image-a-349_1627585586527.jpg?resize=634%2C346&ssl=1",
      subscribed_to_report: true,
      last_signed_in_at: 1648155495,
      confirmed: "2022-02-07T14:31:14.116-05:00",
      sign_in_count: 142,
      internal_role: {
        _id: "62017382cdf2c1345f032433",
        name: "admin",
        created_at: 1644262274,
        granted_by: null,
      },
      permissions: [
        {
          scope: 0,
          scope_id: "678876c8687f86adf678d321",
          name: 0,
          context: {
            company_id: "678876c8687f86adf678d321",
          },
        },
      ],
      additional_internal_permissions: [],
      dismissed_banners: {},
      banner_exposures: {},
      keyboard_shortcuts_on: false,
      company_id: "678876c8687f86adf678d321",
      twilio_verify_email_enabled: false,
      braze_support_portal_access: false,
      message_performance_columns: {},
      favorite_app_groups: [],
      react_navigation_enabled: false,
      sso_enabled: false,
      two_factor_auth_enabled: false,
      is_suspended: false,
      is_company_admin: true,
      is_free_trial: false,
    },
    company: {
      account_status: sharedConstants.COMPANY.ACCOUNT_STATUSES.ACTIVE,
      /** timestamp in ISO 8601 string **/
      canvas_v1_deprecation_extension_date: null,
      /** timestamp in seconds */
      created_at: 1648155495,
      email_body_max_size_override: null,
      email_enabled: true,
      email_footer_max_size_override: null,
      /** SendGrid */
      email_sending_esp: "0",
      /** official time zone name, used for localization so this must be a machine-readable string */
      iana_time_zone: "America/Los_Angeles",
      is_hipaa: false,
      is_partner: false,
      has_multiple_esps: false,
      has_sessions: false,
      is_banned: false,
      manage_developers_separately: false,
      name: "company",
      /** localized time zone name */
      ruby_time_zone: "America/Los_Angeles",
      time_zone: "PST",
      /** localized time zone name */
      localized_time_zone: "(GMT-08:00) Pacific Time (US & Canada)",
      pii: {},
      _id: "678876c8687f86adf678d321",
      is_sandbox: false,
      is_free_trial: false,
      free_trial_allowed_domains: null,
      free_trial_attributes: null,
      event_property_segmentation_entitlement: 10,
      purchase_property_segmentation_entitlement: 10,
    },
    appGroups: [
      {
        apps: [
          {
            id: "6418975797a5d77fe98c9e4b",
            name: "Econometrics iOS",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "iOS",
            uninstallTrackingSupport: true,
            geofenceSupport: true,
            pushProviderType: "apns",
          },
          {
            id: "6418975797a5d77fe98c9e4f",
            name: "Econometrics Android",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "Android",
            uninstallTrackingSupport: true,
            geofenceSupport: true,
            pushProviderType: "fcm",
          },
          {
            id: "6418975797a5d77fe98c9e52",
            name: "Econometrics Web",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "Web",
            uninstallTrackingSupport: false,
            geofenceSupport: false,
            pushProviderType: "web",
          },
          {
            id: "6418975797a5d77fe98c9e55",
            name: "Econometrics Windows 8",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "Windows Universal",
            uninstallTrackingSupport: false,
            geofenceSupport: false,
            pushProviderType: "win",
          },
          {
            id: "6418975797a5d77fe98c9e58",
            name: "Econometrics Kindle",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "Kindle",
            uninstallTrackingSupport: true,
            geofenceSupport: false,
            pushProviderType: "amz",
          },
          {
            id: "6418975797a5d77fe98c9e5b",
            name: "Econometrics Baidu",
            imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
            type: "Android China",
            uninstallTrackingSupport: false,
            geofenceSupport: false,
            pushProviderType: "baidu",
          },
        ],
        sdkInfo: {
          android: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          androidChina: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          ios: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          kindle: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          web: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          roku: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
        },
        id: "63f118660000000000000000",
        api_id: "7c0b3f76-3a69-498b-81d0-6494d4e37010",
        name: "Econometrics",
        imageUrl: "https://s3.amazonaws.com/appboy-staging-dashboard-uploads/ios_apps/images/econometrics.png",
        hasSubscription: false,
        maxAnalyticsSegments: 25,
        facebookAppCredentials: {
          hasValidAccessToken: true,
        },
        languages: [
          {
            languageCode: "ar",
            displayLanguage: "Arabic",
          },
          {
            languageCode: "zh",
            displayLanguage: "Chinese",
          },
          {
            languageCode: "en",
            displayLanguage: "English",
          },
          {
            languageCode: "fr",
            displayLanguage: "French",
          },
          {
            languageCode: "de",
            displayLanguage: "German",
          },
          {
            languageCode: "he",
            displayLanguage: "Hebrew",
          },
          {
            languageCode: "id",
            displayLanguage: "Indonesian",
          },
          {
            languageCode: "it",
            displayLanguage: "Italian",
          },
          {
            languageCode: "ja",
            displayLanguage: "Japanese",
          },
          {
            languageCode: "ko",
            displayLanguage: "Korean",
          },
          {
            languageCode: "ms",
            displayLanguage: "Malay",
          },
          {
            languageCode: "es",
            displayLanguage: "Spanish",
          },
          {
            languageCode: "tl",
            displayLanguage: "Tagalog",
          },
          {
            languageCode: "th",
            displayLanguage: "Thai",
          },
          {
            languageCode: "vi",
            displayLanguage: "Vietnamese",
          },
        ],
        createdAt: 1676744806,
        anyAppsWithUninstallTracking: true,
        maxFileUploadSize: null,
        contentCardMaxSize: 2048,
        contentBlockMaxSize: 51200,
        smsTimeRateLimit: 50000,
        defaultTimeRateLimit: null,
        enableCampaignApprovals: false,
        enableCanvasApprovals: false,
        molDataRetentionCampaignsMonths: 3,
        molDataRetentionCanvasMonths: 3,
        hasAnyFeatureFlags: false,
        hasAnyWebHtmlInAppMessages: false,
        hasAnyHtmlFullInAppMessages: false,
        enableEncryption: false,
        clickThroughTrackingEnabled: false,
      },
      {
        apps: [
          {
            id: "6418975797a5d77fe98c9e5f",
            name: "Robot Wars",
            imageUrl: "https://s3.amazonaws.com/appboy-dashboard-uploads/news/default-news-image.png",
            type: "Android",
            uninstallTrackingSupport: true,
            geofenceSupport: true,
            pushProviderType: "fcm",
          },
        ],
        sdkInfo: {
          android: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          androidChina: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          ios: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          kindle: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          web: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          roku: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
        },
        id: "640f5cd60000000000000000",
        api_id: "771d756d-8711-4240-9c24-701f61b676bc",
        name: "Robot Wars",
        imageUrl: "https://s3.amazonaws.com/appboy-dashboard-uploads/news/default-news-image.png",
        hasSubscription: false,
        maxAnalyticsSegments: 25,
        facebookAppCredentials: {
          hasValidAccessToken: true,
        },
        languages: [
          {
            languageCode: "ar",
            displayLanguage: "Arabic",
          },
          {
            languageCode: "zh",
            displayLanguage: "Chinese",
          },
          {
            languageCode: "en",
            displayLanguage: "English",
          },
          {
            languageCode: "fr",
            displayLanguage: "French",
          },
          {
            languageCode: "de",
            displayLanguage: "German",
          },
          {
            languageCode: "he",
            displayLanguage: "Hebrew",
          },
          {
            languageCode: "id",
            displayLanguage: "Indonesian",
          },
          {
            languageCode: "it",
            displayLanguage: "Italian",
          },
          {
            languageCode: "ja",
            displayLanguage: "Japanese",
          },
          {
            languageCode: "ms",
            displayLanguage: "Malay",
          },
          {
            languageCode: "es",
            displayLanguage: "Spanish",
          },
          {
            languageCode: "tl",
            displayLanguage: "Tagalog",
          },
          {
            languageCode: "th",
            displayLanguage: "Thai",
          },
        ],
        createdAt: 1678728406,
        anyAppsWithUninstallTracking: false,
        maxFileUploadSize: null,
        contentCardMaxSize: 2048,
        contentBlockMaxSize: 51200,
        smsTimeRateLimit: 50000,
        defaultTimeRateLimit: null,
        enableCampaignApprovals: false,
        enableCanvasApprovals: false,
        molDataRetentionCampaignsMonths: 3,
        molDataRetentionCanvasMonths: 3,
        hasAnyFeatureFlags: false,
        hasAnyWebHtmlInAppMessages: false,
        hasAnyHtmlFullInAppMessages: false,
        enableEncryption: false,
        clickThroughTrackingEnabled: false,
      },
      {
        apps: [
          {
            id: "6418975797a5d77fe98c9e63",
            name: "Braze Demo Company",
            imageUrl: "https://s3.amazonaws.com/appboy-dashboard-uploads/news/default-news-image.png",
            type: "Android",
            uninstallTrackingSupport: true,
            geofenceSupport: true,
            pushProviderType: "fcm",
          },
        ],
        sdkInfo: {
          android: {
            latestSeenVersion: "100.0.0.0",
            earliestLiveVersion: "",
          },
          androidChina: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          ios: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          kindle: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          web: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          roku: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
        },
        id: "6411ffd60000000000000000",
        api_id: "454c1dd8-207e-4cbf-a0bd-254f83e9a510",
        name: "Braze Demo Company",
        imageUrl: "https://s3.amazonaws.com/appboy-dashboard-uploads/news/default-news-image.png",
        hasSubscription: false,
        maxAnalyticsSegments: 25,
        facebookAppCredentials: {
          hasValidAccessToken: true,
        },
        languages: [
          {
            languageCode: "ar",
            displayLanguage: "Arabic",
          },
          {
            languageCode: "zh",
            displayLanguage: "Chinese",
          },
          {
            languageCode: "en",
            displayLanguage: "English",
          },
          {
            languageCode: "fr",
            displayLanguage: "French",
          },
          {
            languageCode: "de",
            displayLanguage: "German",
          },
          {
            languageCode: "id",
            displayLanguage: "Indonesian",
          },
          {
            languageCode: "it",
            displayLanguage: "Italian",
          },
          {
            languageCode: "ja",
            displayLanguage: "Japanese",
          },
          {
            languageCode: "ko",
            displayLanguage: "Korean",
          },
          {
            languageCode: "ms",
            displayLanguage: "Malay",
          },
          {
            languageCode: "es",
            displayLanguage: "Spanish",
          },
          {
            languageCode: "tl",
            displayLanguage: "Tagalog",
          },
          {
            languageCode: "th",
            displayLanguage: "Thai",
          },
          {
            languageCode: "vi",
            displayLanguage: "Vietnamese",
          },
        ],
        createdAt: 1678901206,
        anyAppsWithUninstallTracking: false,
        maxFileUploadSize: null,
        contentCardMaxSize: 2048,
        contentBlockMaxSize: 51200,
        smsTimeRateLimit: 50000,
        defaultTimeRateLimit: null,
        enableCampaignApprovals: false,
        enableCanvasApprovals: false,
        molDataRetentionCampaignsMonths: 3,
        molDataRetentionCanvasMonths: 3,
        hasAnyFeatureFlags: false,
        hasAnyWebHtmlInAppMessages: false,
        hasAnyHtmlFullInAppMessages: false,
        enableEncryption: false,
        clickThroughTrackingEnabled: false,
      },
      {
        apps: [],
        sdkInfo: {
          android: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          androidChina: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          ios: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          kindle: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          web: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
          roku: {
            latestSeenVersion: "",
            earliestLiveVersion: "",
          },
        },
        id: "6418968b97a5d77fe98c9b30",
        api_id: "c805ea55-b0d1-4cd0-87cd-ae928ddc8a1a",
        name: "Braze Demo Company",
        imageUrl: "https://s3.amazonaws.com/appboy-dashboard-uploads/news/default-news-image.png",
        hasSubscription: false,
        maxAnalyticsSegments: 25,
        facebookAppCredentials: {
          hasValidAccessToken: true,
        },
        languages: [],
        createdAt: 1679333003,
        anyAppsWithUninstallTracking: false,
        maxFileUploadSize: null,
        contentCardMaxSize: 2048,
        contentBlockMaxSize: 51200,
        smsTimeRateLimit: 50000,
        defaultTimeRateLimit: null,
        enableCampaignApprovals: false,
        enableCanvasApprovals: false,
        molDataRetentionCampaignsMonths: 3,
        molDataRetentionCanvasMonths: 3,
        hasAnyFeatureFlags: false,
        hasAnyWebHtmlInAppMessages: false,
        hasAnyHtmlFullInAppMessages: false,
        enableEncryption: false,
        clickThroughTrackingEnabled: false,
      },
    ],

    otherCompanies: [],
  },
  features: baseFakeFeatures,
  debug: null,
  highCharts: {
    colors: ["#ffffff"],
    exportChartUrl: "/highcharts/export",
    exportCsvUrl: "/highcharts/csv_export",
  },
  pusher: {
    key: "",
    cluster: "",
  },
  email: {
    default_app_group_name: "",
    default_app_group_from_address: "",
  },
  whatsApp: {
    app_id: 966615097624967,
    config_id: 446333001251445,
    meta_marketing_config_id: 1994504651003513,
  },
  external_api: {
    base_url: "",
    base_url_without_https: "",
  },
  webSdkBackend: "https://stub-api.herokuapp.com/v3",
  webSdkApiKey: "dashboard_teaspoon_specs",
  railsEnv: "development",
  env: "development",
  version: "1.2.3",
  EMAIL_ACCOUNT_NON_ISOLATED_RATE_LIMIT: 1000,
  sentryEnv: "test",
  isPce: false,
  safariPushWebsiteId: "foo",
  facebookAppCredentials: {
    hasValidAccessToken: true,
  },
  dndSocialIconRoot: "https://braze-social-icons-staging.s3.amazonaws.com",
  brazeLinkShortening: {
    defaultDomain: "brz.ai",
  },
  cdn: {
    urls: ["https://cdn.braze.com", "cdn.braze.eu"],
  },
  featureLimitations: {
    "63f118660000000000000000": [
      {
        limit_key: "object_creation_limits.canvas",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.campaign",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "iam_campaigns_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "canvas_steps_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.voucher_group",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_access_page_vouchers_list",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "total_volume_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_view_api_keys",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "developer_creation_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_limit",
        limit_value: 20,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_limit",
        limit_value: 100,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_use_connected_content",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "user_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_transformation",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_ai_item_recommendations",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_churn",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_events",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_dashboard_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_cdi",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_currents",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_sharing",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_query_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_segment_extensions",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_feature_flags",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_conversion_dashboard",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
    ],
    "640f5cd60000000000000000": [
      {
        limit_key: "object_creation_limits.canvas",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.campaign",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "iam_campaigns_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "canvas_steps_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.voucher_group",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_access_page_vouchers_list",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "total_volume_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_view_api_keys",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "developer_creation_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_limit",
        limit_value: 20,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_limit",
        limit_value: 100,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_use_connected_content",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "user_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_transformation",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_ai_item_recommendations",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_churn",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_events",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_dashboard_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_cdi",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_currents",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_sharing",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_query_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_segment_extensions",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_feature_flags",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_conversion_dashboard",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
    ],
    "6411ffd60000000000000000": [
      {
        limit_key: "object_creation_limits.canvas",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.campaign",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "iam_campaigns_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "canvas_steps_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.voucher_group",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_access_page_vouchers_list",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "total_volume_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_view_api_keys",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "developer_creation_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_limit",
        limit_value: 20,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_limit",
        limit_value: 100,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_use_connected_content",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "user_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_transformation",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_ai_item_recommendations",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_churn",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_events",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_dashboard_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_cdi",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_currents",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_sharing",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_query_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_segment_extensions",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_feature_flags",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_conversion_dashboard",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
    ],
    "6418968b97a5d77fe98c9b30": [
      {
        limit_key: "object_creation_limits.canvas",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.campaign",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "iam_campaigns_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "canvas_steps_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "object_creation_limits.voucher_group",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_access_page_vouchers_list",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "total_volume_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_view_api_keys",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "developer_creation_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_limit",
        limit_value: 20,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_copy_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_chat_completion_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_limit",
        limit_value: 10,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "generate_images_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_limit",
        limit_value: 100,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "gpt_qa_message_rate_period",
        limit_value: "day",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_limit",
        limit_value: 200,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "liquid_assistant_rate_period",
        limit_value: "minute",
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_use_connected_content",
        limit_value: true,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "user_limit",
        limit_value: null,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_transformation",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_ai_item_recommendations",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_churn",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_predictive_events",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_dashboard_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_cdi",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_currents",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_data_sharing",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_query_builder",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_segment_extensions",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_feature_flags",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
      {
        limit_key: "can_only_view_landing_page_conversion_dashboard",
        limit_value: false,
        limit_source: "default",
        limit_level: 2000,
      },
    ],
  },
};

/**
 * For usage in testing and storybook, this allows mocking the dashboard config and overriding any sub-piece of the configuration.
 */
export function getFakeDashboardConfig(
  configOverrides: PartialDashboardConfig,
  previous = baseFakeConfig
): DashboardConfig {
  return mergeDeep(structuredClone(previous), configOverrides);
  // DO NOT ADD ANYTHING ELSE HERE, PUT IT IN THE MOCK ABOVE
}
